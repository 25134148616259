
































































import { multiSearch } from '@/utils/Common'
import { Chat } from '@tada-team/tdproto-ts'
import { groupsStore } from '@/store'
import { getDefaultFuseOptions, isNotUndefined } from '@/utils'
import Fuse from 'fuse.js'
import { Component, Prop, Vue } from 'vue-property-decorator'

interface SelectorOption {
  value: string | null;
  label: string;
}

@Component({
  name: 'SectionGroupGroupSelector',
  components: {
    GroupIcon: () => import('@/components/UI/GroupIcon.vue'),
  },
})
export default class SectionGroupGroupSelector extends Vue {
  @Prop({
    type: String,
    default: null,
  }) readonly value!: string | null

  @Prop({
    type: Boolean,
    default: false,
  }) readonly disable!: boolean

  @Prop({
    type: String,
    default: '',
  }) readonly error!: string

  fuseOptions: Fuse.IFuseOptions<SelectorOption> = getDefaultFuseOptions(['label'])

  // bodgy bodge for our z-index values vs quasar default ones
  popupContentStyle: Partial<CSSStyleDeclaration> = { zIndex: '9999' }
  filteredOptions: SelectorOption[] = []

  get groups (): Partial<Record<string, Chat>> {
    return groupsStore.state.data
  }

  get hasValue (): boolean {
    return !!this.value && this.value.length > 0
  }

  get placeholder (): string | null {
    if (this.hasValue) return null
    return this.$t('common.dropDownNotSelected').toString()
  }

  get fuse (): Fuse<SelectorOption> {
    return new Fuse(this.options, this.fuseOptions)
  }

  get options (): SelectorOption[] {
    return Object
      .values(this.groups)
      .filter(isNotUndefined)
      .map(s => ({ value: s.jid, label: s.displayName }))
      .sort((a, b) => a.label > b.label ? 1 : -1)
  }

  get computedValue (): SelectorOption | null {
    return this.options.find(o => o.value === this.value) ?? null
  }

  set computedValue (v) {
    this.$emit('input', v)
  }

  groupDisplayName (jid: string | null): string {
    if (!jid) return ''
    return groupsStore.getters.displayName(jid)
  }

  searchFn (text: string): SelectorOption[] {
    return multiSearch((text: string) => this.fuse.search(text), text, 'value')
  }

  filterFn (userInput: string, update: (callback: () => void) => unknown): void {
    const val = userInput.trim()

    // filter only starting with second character
    update(() => {
      this.filteredOptions = val.length < 2
        ? this.options
        : this.searchFn(val)
    })
  }
}
